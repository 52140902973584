import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivacyPolicyPage from '../pages/privacy-policy';
import TermsAndConditionsPage from '../pages/terms-conditions';
import HomePage from '../pages/home/HomePage';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route >
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />

        </Route>
      </Routes>

    </BrowserRouter>
  );
}

export default Router;
