import React from "react"
import { COLOR } from "../theme"

export const BottomDetailsContainer = ({ icon, title, description }) => {
  return (
    <div
      className="flex column align-center "
      style={{ textAlign: "center", padding: 10,flex:1 }}
    >
      <div className="flex  yatraIconContainer">
        <img src={icon} className="yatraIcon" alt="NewsIcon" />
      </div>
      <span style={{ color: COLOR.primary, fontFamily: "Poppins-Bold" }}>
        {title}
      </span>
      <span style={{ color: "#525257", fontFamily: "Poppins-Medium" }}>
        {description}
      </span>
    </div>
  )
}
