import React, { useEffect } from "react";
import "./PrivacyPolicyPage.css"
import { Helmet } from "react-helmet";
import { Events, trackEvent } from "../../firebase/analytics";
const PrivacyPolicyPage = () => {
  useEffect(() => {
    trackEvent(Events.privacy_policy_visit)
  }, [])
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Ayodhya Darshan</title>
        <meta name="description" content="Read about our commitment to your privacy and how we handle your data on Ayodhya Darshan." />
        <meta name="keywords" content="Ayodhya Darshan privacy, data protection, user privacy policy, privacy terms" />
        <link rel="canonical" href="https://www.ayodhyadarshan.app/privacy-policy" />
      </Helmet>
      {/* <section className="wrapper"> */}
      {/* <div className="container"> */}
      <div className="privacy-policy-content">
        <h2>Privacy Policy for Ayodhya Darshan Mobile Application</h2>

        <p>Welcome to Ayodhya Darshan, a mobile application designed to offer a unique and enriching virtual experience of Ayodhya. Your privacy is of paramount importance to us, and this Privacy Policy is designed to inform you about our practices regarding the collection, use, and disclosure of information that we may receive through our mobile application.</p>

        <h3>Collection of Information</h3>

        <p>Ayodhya Darshan does not directly collect any personal information from its users. Our application is designed to provide you with services without the need for you to share personal details such as your name, email address, or contact information.</p>

        <h3>Use of Firebase Analytics and Crash Reporting</h3>

        <p>While we do not collect personal information directly, we use Firebase, a third-party service provided by Google, for analytics and crash reporting. This helps us understand how our app is used and enables us to improve your experience by identifying and fixing issues that may cause the app to crash.</p>

        <p>Firebase Analytics may collect data related to your app usage, such as how often you use the application, which features you use the most, and performance data. Crash reports include information about your device and what was happening within the application at the time of the crash. However, this information is collected in a way that does not personally identify you.</p>

        <h3>Information Security</h3>

        <p>We are committed to ensuring the security of your information. We implement appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of any potential data we might handle.</p>

        <h3>Third-Party Services</h3>

        <p>Apart from Firebase for analytics and crash reporting, we do not share any data with third-party services. We also do not sell or rent any potential data to third parties.</p>

        <h3>Cookies and Similar Technologies</h3>

        <p>As a mobile application, Ayodhya Darshan does not use cookies. However, Firebase may use certain technologies to collect data for analytics and crash reporting purposes.</p>

        <h3>Data Retention</h3>

        <p>Any data collected through Firebase Analytics and Crash Reporting is retained only for as long as necessary to fulfill the purposes outlined in this Privacy Policy.</p>

        <h3>Children's Privacy</h3>

        <p>Ayodhya Darshan is designed for a general audience and does not knowingly collect any personal information from children under the age of 13.</p>

        <h3>Updates to Our Privacy Policy</h3>

        <p>We may update this Privacy Policy occasionally to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review our Privacy Policy periodically for the latest information on our privacy practices.</p>

        <h3>Contact Us</h3>

        <p>If you have any questions or concerns about this Privacy Policy or our practices, please feel free to contact us through the support features within the Ayodhya Darshan application.</p>

        <p>Thank you for choosing Ayodhya Darshan. We are dedicated to providing you with an enjoyable and secure experience.</p>

      </div>
      {/* </div> */}
      {/* </section> */}
    </>
  );
};


export default PrivacyPolicyPage;