import React, { useEffect } from "react";
import "./TermsAndConditionsPage.css"
import { Helmet } from "react-helmet";
import { Events, trackEvent } from "../../firebase/analytics";
const TermsAndConditionsPage = () => {
  useEffect(() => {
    trackEvent(Events.terms_conditions_visit)
  }, [])
  return (
    <>
      <Helmet>
        <title>Terms and Conditions - Ayodhya Darshan</title>
        <meta name="description" content="Understand the rules and guidelines for using the Ayodhya Darshan platform." />
        <meta name="keywords" content="Ayodhya Darshan terms, user guidelines, usage policy, terms of service" />
        <link rel="canonical" href="https://www.ayodhyadarshan.app/terms-and-conditions" />
      </Helmet>
      <div className="privacy-policy-content">
        <h2>Terms and Conditions for Ayodhya Darshan Mobile Application</h2>

        <p>Welcome to Ayodhya Darshan! These terms and conditions outline the rules and regulations for the use of the Ayodhya Darshan Mobile Application, hereinafter referred to as "Application".</p>

        <p>By accessing this Application, we assume you accept these terms and conditions in full. Do not continue to use Ayodhya Darshan if you do not accept all of the terms and conditions stated on this page.</p>

        <h3>License</h3>

        <p>Unless otherwise stated, Ayodhya Darshan and/or its licensors own the intellectual property rights for all material on Ayodhya Darshan. All intellectual property rights are reserved. You may view and/or print pages from the Application for your own personal use subject to restrictions set in these terms and conditions.</p>

        <p>You must not:</p>
        <ul>
          <li>Republish material from Ayodhya Darshan</li>
          <li>Sell, rent or sub-license material from Ayodhya Darshan</li>
          <li>Reproduce, duplicate or copy material from Ayodhya Darshan</li>
          <li>Redistribute content from Ayodhya Darshan (unless content is specifically made for redistribution)</li>
        </ul>

        <h3>User Comments</h3>

        <p>Certain parts of this Application offer the opportunity for users to post and exchange opinions, information, material, and data ('Comments'). Ayodhya Darshan does not screen, edit, publish or review Comments prior to their appearance on the website and Comments do not reflect the views or opinions of Ayodhya Darshan, its agents or affiliates.</p>

        <p>Comments reflect the view and opinion of the person who posts such view or opinion. To the extent permitted by applicable laws, Ayodhya Darshan shall not be responsible or liable for the Comments or for any loss cost, liability, damages or expenses caused and or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this Application.</p>

        <h3>Applicable Law and Jurisdiction</h3>

        <p>These terms and conditions are governed by and construed in accordance with the laws of [Your Country] and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>

        <h3>Disclaimer</h3>

        <p>The materials on Ayodhya Darshan's Application are provided "as is". Ayodhya Darshan makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>

        <p>Further, Ayodhya Darshan does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Application or otherwise relating to such materials or on any sites linked to this site.</p>

        <h3>Modifications</h3>

        <p>Ayodhya Darshan may revise these terms and conditions for its Application at any time without notice. By using this Application, you are agreeing to be bound by the then current version of these Terms and Conditions of Use.</p>

        <h3>Your Privacy</h3>

        <p>Please read our Privacy Policy.</p>

        <h3>Reservation of Rights</h3>

        <p>We reserve the right to request that you remove all links or any particular link to our Application. You approve to immediately remove all links to our Application upon such request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuing to link to our Application, you agree to be bound to and abide by these linking terms and conditions.</p>

        <h3>Removal of links from our Application</h3>

        <p>If you find any link on our Application or any linked web site objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.</p>

        <p>Whilst we endeavour to ensure that the information on this Application is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the Application remains available or that the material on the Application is kept up to date.</p>

        <h3>Contact Information</h3>

        <p>If you have any queries regarding any of our terms, please contact us through the support features within the Application.</p>

      </div>
    </>
  );
};

export default TermsAndConditionsPage;