import React from 'react';

import './AppButton.css'; // Assuming you have created a corresponding CSS file.

const AppButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="appButton"
    >
      Contact us
    </button>
  );
};

export default AppButton;