import React from "react"
import "./HomePage.css"
// import MandirLogo from "../../assets/images/MandirLogo.svg"
import DarshanLogo from "../../assets/images/Darshan.svg"
// import SmallDeviceLogo from "../../assets/images/smallDeviceLogo.svg"
import AyodhyaLogo from "../../assets/images/Ayodhya.svg"
import NewsIcon from "../../assets/images/News.svg"
import YatraIcon from "../../assets/images/yatra.svg"
import LocationIcon from "../../assets/images/location.svg"
import FooterLogoIcon from "../../assets/images/footerLogo.svg"
import GooglePlayIcon from "../../assets/images/googlePlay.svg"
import AppIcon from "../../assets/images/MobileAppImage.png"
import { BottomDetailsContainer } from "../../components/bottomDetailsContainer"
import { FONT_CONSTANT } from "../../assets/constant"
import LeftImage from "../../assets/images/LeftTempleIcons"
import RightImage from "../../assets/images/RightTempleIcons"
import { useMediaQuery } from "react-responsive"
import AppButton from "./components/AppButton"
// import MockUpsContainer from "./components/MockUpsContainer"
import { Helmet } from "react-helmet"
import { NavLink } from "react-router-dom"
import { analytics } from "../../firebase"
import { Events, trackEvent } from "../../firebase/analytics"
import { useEffect } from "react"

const HomePage = React.memo(() => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1100px)" })
  const isTablet = useMediaQuery({ query: "(min-width: 600px)" })
  const isMinTablet = useMediaQuery({ query: "(min-width: 480px)" })
  const isSmallDevice = useMediaQuery({ query: "(max-width: 600px)" })
  useEffect(() => {
    trackEvent(Events.home_page_visit)
  }, [])
  const responsiveSVG = () => {
    if (isBigScreen) {
      return {
        width: 280,
        height: 454,
      }
    } else if (isTablet) {
      return {
        width: 220,
        height: 404,
      }
    } else if (isMinTablet) {
      return {
        width: 170,
        height: 280,
      }
    } else {
      return {
        width: 150,
        height: 286,
      }
    }
  }
  return (
    <>
      <Helmet>
        <title>Ayodhya Darshan - Discover Devotion and History in the Heart of Ayodhya</title>
        <meta name="description" content="Take a holy trip to Ayodhya. Explore temples, traditions, and the old culture of this special city." />
        <meta name="keywords" content="Ayodhya, Ram Mandir, Hanuman Garhi, Sarayu River, Ayodhya Tour, Hindu Pilgrimage, Ramayana" />
        <link rel="canonical" href="https://www.ayodhyadarshan.app/" />
        <link rel="icon" type="image/png" href="../../assets/images/ayodha_logo.png" sizes="16x16" />

      </Helmet>
      <section className="wrapper header" >
        <div className="container" >
          <div
            style={{
              display: "flex",
              width: "100%",
              flexGrow: 1,
              justifyContent: "space-between",
              padding: "20px 0px",
            }}
          >
            <div className="flex align-center ">
              {/* <img src={MandirLogo} className="logo" alt="logo" /> */}
              {isSmallDevice ? (
                <img
                  src={AyodhyaLogo}
                  alt="Ayodhya-Icon"
                  className="logo"
                />
              ) : (
                <>
                  <img src={AyodhyaLogo} className="logo" alt="logo" />
                  <img src={DarshanLogo} className="logo" alt="logo" />
                </>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <AppButton /> */}
            </div>
          </div>
        </div>
      </section>
      <section
        className="wrapper"
        style={{ background: "rgba(241,241,241,1)", paddingTop: 100 }}
      >
        <div className="container " style={{ marginTop: 20 }}>
          <div className="gridContainer">
            <div className="leftTemplateStyle">
              <LeftImage
                onFirstImageClick={() => alert("first image clicked")}
                onSecondImageClick={() => alert("on Second image clicked")}
                onThirdImageClick={() => alert("on Third image clicked")}
                {...responsiveSVG()}
              />
            </div>

            <div className="centerTemplateStyle">
              <div
                className="column align-center"
                style={{ textAlign: "center" }}
              >
                <p className="title">
                  Your virtual gateway to the sacred city of Ayodhya
                </p>
                <p className="subTitle">
                  Welcome to Ayodhya Darshan, your gateway to the spiritual
                  heart of India. Immerse yourself in the sacred city of
                  Ayodhya.
                </p>
                <a href="https://play.google.com/store/apps/details?id=com.brovitech.ayodhyadarshan.android" target="_blank" rel="noopener noreferrer">

                  <img
                    src={GooglePlayIcon}
                    alt="Google-icon"
                    style={{ width: 189, height: 56 }}
                  />
                </a>
              </div>
            </div>
            <div className="rightTemplateStyle">
              <div>
                <RightImage
                  onFirstImageClick={() => alert("first image clicked")}
                  onSecondImageClick={() => alert("on Second image clicked")}
                  onThirdImageClick={() => alert("on Third image clicked")}
                  {...responsiveSVG()}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="wrapper"
        style={{ background: "rgba(241,241,241,1)" }}
      >
        <div className="container justify-center" style={{ marginTop: 20 }}>
          <img src={AppIcon} alt="" style={{ width: "80%", maxWidth: 700 }} />
        </div>
      </section>
      <section
        className="wrapper"
        style={{ background: "rgba(241,241,241,1)" }}
      >
        <div
          className="container"
          style={{ justifyContent: "space-between", flexWrap: "wrap" }}
        >
          <BottomDetailsContainer
            icon={NewsIcon}
            title="News feed"
            description="Stay Connected with Ayodhya's Pulse; Experience the Latest News Unfold Seamlessly"
          />
          <BottomDetailsContainer
            icon={YatraIcon}
            title="Yatra"
            description="Embark on a Spiritual Journey; Explore Ayodhya's Sacred Temples with Ease"
          />
          <BottomDetailsContainer
            icon={LocationIcon}
            title="Map"
            description="Easily locate and pinpoint the exact locations of Ayodhya's revered temples."
          />
        </div>
      </section>
      <footer className="wrapper" style={{ background: "#000", padding: 20 }}>
        <div className="container">
          <div className="footerContainer">
            <div className="footerLeftItem flex column align-center justify-center">
              <img src={FooterLogoIcon} alt="Ayodhya-Icon" />
              <span
                style={{
                  color: "#919191",
                  fontFamily: FONT_CONSTANT.regular,
                  fontSize: 14,
                  marginTop: 10,
                }}
              >
                copyright@ Brovitech Solutions LLP
              </span>
            </div>
            <div className="footerCenterItem flex justify-center align-center">
              <div className="privacySection">
                <span
                  style={{
                    color: "#FFF",
                    fontFamily: FONT_CONSTANT.medium,
                    fontSize: 14,
                    textAlign: "center",
                  }}
                >
                  <NavLink to='/privacy-policy' style={{ color: 'white' }} onClick={() => {
                    trackEvent(Events.privacy_policy_clicked)
                  }}> Privacy Policy</NavLink>

                </span>
                <span
                  style={{
                    background: "#FFF",
                    width: "0.2px",
                    height: 15,
                    margin: "0 10px",
                  }}
                ></span>
                <span
                  style={{
                    color: "#FFF",
                    fontFamily: FONT_CONSTANT.medium,
                    fontSize: 14,
                    textAlign: "center",
                  }}
                >
                  <NavLink to='/terms-and-conditions' style={{ color: 'white' }} onClick={() => {
                    trackEvent(Events.terms_conditions_clicked)
                  }}> Terms & Conditions</NavLink>
                </span>
              </div>
            </div>
            <div className="footerRightItem flex column align-center">
              <span
                style={{
                  color: "#FFF",
                  fontFamily: FONT_CONSTANT.regular,
                  fontSize: 14,
                  marginTop: 10,
                  textAlign: "center",
                }}
              >
                Download the app now
              </span>
              <div style={{ flex: 1, display: "flex" }}>
                <a href="https://play.google.com/store/apps/details?id=com.brovitech.ayodhyadarshan.android" target="_blank" rel="noopener noreferrer">

                  <img
                    src={GooglePlayIcon}
                    alt="Google Play Store"
                    style={{ width: "100%", height: "100%" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
})
export default HomePage;