import { logEvent } from 'firebase/analytics';
import { analytics } from '..';
const isDev = process.env.NODE_ENV === 'development';

const Events = {
  playstore_icon_clicked: 'playstore_icon_clicked',
  privacy_policy_clicked: 'privacy_policy_clicked',
  terms_conditions_clicked: 'terms_conditions_clicked',
  home_page_visit: "home_page_visit",
  privacy_policy_visit: "privacy_policy_visit",
  terms_conditions_visit: "terms_conditions_visit",
  contact_us_clicked: 'contact_us_clicked'
};
function trackEvent(eventName, params = {}) {
  if (isDev || !analytics) {
    console.log('trackEvent_', eventName, params);
  } else {
    logEvent(analytics, eventName, params);
  }
}
function trackScreen(screenName) {
  trackEvent('screen_view', screenName);
}
export { trackEvent, trackScreen, Events };